<template>
  <div class="mt-3">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <CButton color="primary" class="mr-2 mb-2">Ai SEARCH</CButton>
        <CButton color="primary" class="mr-2 mb-2">FELLOWSHIPS</CButton>
        <CButton color="primary" class="mr-2 mb-2">OBSERVERSHIPS</CButton>
        <CButton color="primary" class="mr-2 mb-2">CLINICAL PLACEMENTS</CButton>
      </div>
      <div>
        <CButton color="primary" to="/tfw">Back</CButton>
      </div>
    </div>
    <CRow class="mt-2">
      <CCol
        md="4"
        class="d-flex justify-content-around"
        v-for="(item, index) of items"
        :key="index"
      >
        <CCard class="overflow-hidden">
          <div class="p-2">
            <h5 class="m-0 font-weight-bold" :title="item.title" style="font-size: 19px;">
              {{ item.title }}
            </h5>
          </div>
          <img
            class="card-img-top"
            :src="item.image"
            style="height: 120px; object-fit: cover; border-radius: 0"
          />
          <CCardBody class="p-2">
            <div class="container-fluid">
              <div class="row mb-1">
                <div class="col-4 text-muted fw-semibold">Hospital</div>
                <div class="col-8 text-truncate">: {{ item.hospital }}</div>
              </div>
              <div class="row mb-1">
                <div class="col-4 text-muted fw-semibold">Type</div>
                <div class="col-8 text-truncate">: {{ item.type }}</div>
              </div>
              <div class="row mb-1">
                <div class="col-4 text-muted fw-semibold">Period</div>
                <div class="col-8 text-truncate">: {{ item.period }}</div>
              </div>
              <div class="row mb-1">
                <div class="col-4 text-muted fw-semibold">Start Date</div>
                <div class="col-8 text-truncate">: {{ item.start_date }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-2">
              <CButton
                size="sm"
                color="primary"
                class="btn px-3"
                to="/tfw/placements/view"
              >
                View Details
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          image: "/img/tfw/placements/placement_1.png",
          title: "Practice Learning Placement Year 1 - Mental Health Nursing",
          hospital: "York Care Home",
          type: "3yr - Adult Nursing",
          period: "8 weeks",
          start_date: "5th May 2024",
        },
        {
          image: "/img/tfw/placements/placement_2.png",
          title: "Practice Learning Placement Year 2 - Children's Nursing",
          hospital: "Children's General",
          type: "3yr - Adult Nursing",
          period: "6 weeks",
          start_date: "5th May 2024",
        },
        {
          image: "/img/tfw/placements/placement_3.png",
          title: "Practice Learning Placement Year 3  -  Adult Nursing",
          hospital: "St. James General",
          type: "3yr - Adult Nursing",
          period: "8 weeks",
          start_date: "5th May 2024",
        },
      ],
    };
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.text-2-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title {
  font-weight: 700;
  line-height: 1.235;
  font-size: 22px;
}

.img-card {
  height: 200px;
}

.triple-space::after {
  content: "\00a0\00a0\00a0";
}
</style>
